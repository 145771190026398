<script setup>
import { HUB } from '@/hub'
import { onMounted, ref } from 'vue'
import { useStore } from "vuex";
import { Gems } from "@/gems";
import Menu from '../components/Menu'


const users = ref([])
const defaultImage = require('@/images/default.png')
const store = useStore()
let arrUsers

async function GetUsers() {

  const response = await HUB.CallBackEnd({
    action: HUB.Action("get-users")
  });

  users.value = response.data
  arrUsers = response.data
}

function normalizeString(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

function SearchUsers(value) {
  if (value) {
    const normalizedValue = normalizeString(value);
    users.value = arrUsers.filter((elem) => {
      return normalizeString(elem.internal).includes(normalizedValue)
        || normalizeString(elem.full_name).includes(normalizedValue)
        || normalizeString(elem.name).includes(normalizedValue);
    });
  } else {
    users.value = arrUsers;
  }

  const divUsersScrollbar = document.getElementById('div-users-list');

  if (divUsersScrollbar.scrollHeight > divUsersScrollbar.clientHeight) {
    divUsersScrollbar.classList.add('div-without-scrollbar');
  } else {
    divUsersScrollbar.classList.remove('div-without-scrollbar');
  }
}

async function ChangeUserStatus(user, type) {
  let status

  switch (type) {
    case 'set-active-status':
      user.active = !Number(user.active)
      status = user.active

      if (user.user_id == store.getters.User.id)
        store.getters.User.apps[user.app].active = status
      break;

    case 'set-admin-status':
      user.admin = !Number(user.admin)
      status = user.admin

      if (user.user_id == store.getters.User.id)
        store.getters.User.apps[user.app].admin = status
      break;
  }

  const response = await HUB.CallBackEnd({
    action: HUB.Action(type),
    data: {
      app: user.app,
      status,
      user_id: user.user_id
    }
  })

  if (response.error) {
    GetUsers()
  } else if (type == 'set-active-status') {
    Gems.CallWS({ app: "kpi", action: "update-users-data" });
  }
}

function IsUserInactive(user) {
  return Object.values(user.apps).reduce((prev, current) => prev || Number(current.active), 0) == 0
}

onMounted(GetUsers)
</script>


<template>
  <div id="div-container" class="main-container-background">

    <Menu></Menu>

    <div class="page-container">

      <div class="div-search-users">
        <img class="inpeople-default-image" src="../images/inpeople.png">
        <div class="div-search-input">
          <label for="search-users">Pesquisar</label>
          <input type="text" id="search-users" @keyup="SearchUsers($event.target.value)">
        </div>
      </div>

      <div id="div-user-data" class="div-users-list-container">

        <div id="div-user-header">
          <div></div>
          <div class="div-user-internal">N interno</div>
          <div>Nome completo</div>
          <div>Nome profissional</div>

          <div v-for="(app, i) in store.getters.Apps" :key="i">
            <div>{{ app }}</div>

            <div class="div-toggle-header">
              <span>(ativo)</span>
              <span>(admin)</span>
            </div>
          </div>
        </div>

        <div class="div-users-list scrollbar" id="div-users-list">
          <div class="div-user-container" :class="{
            'user-alt': ind % 2 == 0,
            'inactive-user': IsUserInactive(user),
          }" v-for="(user, ind) in users" :key="user.id">


            <img id="div-user-photo" class="div-user-photo" :src="user.src ?? defaultImage"
              @error="$event.target.src = defaultImage">

            <div class="div-user-internal">
              {{ user.internal }}
            </div>
            <div class="div-user-fullname">
              {{ user.full_name }}
            </div>
            <div class="div-user-professional-name">
              {{ user.name }}
            </div>

            <div class="div-apps-container" v-for="(appName, i) in store.getters.Apps" :key="i">
              <div>
                <i @click="ChangeUserStatus(user.apps[appName], 'set-active-status')"
                  class="fa clickable fa-toggle-off active" :class="{
                    'inactive': user.apps[appName].active == 0,
                    'disabled': !store.getters.User.apps[appName] || store.getters.User.apps[appName].active == 0 || store.getters.User.apps[appName].admin == 0
                  }"></i>

                <i @click="ChangeUserStatus(user.apps[appName], 'set-admin-status')"
                  class="fa clickable fa-toggle-off admin" :class="{
                    'inactive': user.apps[appName].admin == 0,
                    'disabled': !store.getters.User.apps[appName] || store.getters.User.apps[appName].active == 0 || store.getters.User.apps[appName].admin == 0
                  }"></i>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<style scoped>
#div-container {
  display: flex;
  min-height: 100vh;
}

.div-without-scrollbar {
  padding-right: 5px;
}

.div-search-users {
  display: flex;
  justify-content: space-between;
}

.div-search-input {
  display: flex;
  gap: 5px;
  height: 30%;
  width: 30%;
  align-items: center;
}

.div-search-input input {
  border-radius: 5px;
  color: #000 !important;
  padding: 7px 8px;
  width: 100%;
  margin-left: 2%;
}

.page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 35px 25px 14px;
}

.logo-inpeople {
  width: 10%;
  margin-bottom: calc(.8% + 28px);
  opacity: .3;
}

.div-users-list-container {
  width: 100%;
  margin: auto;
  overflow-y: hidden;
}

.div-users-list {
  overflow-y: auto;
  height: 75vh;
  border-radius: 7px;
  font-weight: 300;
}

.div-user-container {
  align-items: center;
  border-radius: 5px;
}

.inactive-user {
  background-color: #f3f3f308 !important;
  color: #ffffff3b !important;
}

.inactive {
  color: #808090 !important;
}

.user-alt {
  background-color: #231f20;
}

#div-user-header {
  font-family: "Space Grotesk", sans-serif;
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 0.1em;
  background: linear-gradient(45deg, rgba(135, 255, 165, 1), rgba(205, 176, 255, 1) 55%, rgb(188 118 201) 75%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.div-user-container,
#div-user-header {
  display: grid;
  grid-template-columns: 30px .7fr 1.6fr 1.3fr repeat(5, .2fr);
  gap: .2%;
  justify-content: center;
  padding: 5px 10px;
}

.div-apps-container div {
  display: flex;
}

.div-apps-container i {
  font-size: 1.5em;
  width: 50px;
  text-align: center;
}

.div-user-container.inactive-user .div-user-photo {
  opacity: 0.3;
}

.div-user-photo {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  box-shadow: 0 3px 4px rgb(0 0 0 / 10%), 0 4px 10px rgb(0 0 0 / 20%);
  vertical-align: bottom;
  object-fit: cover;
}

.div-toggle-header {
  font-size: 0.6rem;
  text-transform: lowercase;
  display: flex;
  -webkit-text-fill-color: lightgray;
}

.div-toggle-header span {
  width: 50px;
}

.div-user-internal {
  text-align: center;
}

.active,
.admin {
  rotate: 180deg;
}

.inactive,
.not-admin {
  rotate: 0deg;
}

.active {
  color: rgb(135 255 165);
}

.not-admin {
  color: rgb(205 176 255) !important;
}
</style>
